<template>
    <div class="detail-contain">
        <div class="back-box">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
                <el-breadcrumb-item class="first">学生达人榜</el-breadcrumb-item>
                <el-breadcrumb-item class="second">学生数据详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="back-btn">
                <el-button @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="head-box">
            <div class="first-box"></div>
            <div class="second-box">
                <div class="left-box">
                    <div class="left-top-box">
                        <div>百家号排名</div>
                        <span>TOP.{{rank}}</span>
                    </div>
                    <div class="left-bottom-box">
                        <div class="left-bottom-avatar">
                            <img :src="stuInfo.avatar" alt="">
                        </div>
                        <div class="left-bottom-headInfo">
                            <div class="headInfo-name">
                                {{stuInfo.name}}
                            </div>
                            <div class="headInfo-sign">
                                {{stuInfo.sign}}
                            </div>
                            <div class="headInfo-url" @click="gotoUrl(stuInfo.url)">
                                <span>直达作者主页</span>
                                <i class="el-icon-position"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="middle-box">
                    <div class="update-time">
                        <span>更新时间：</span>
                        <span>{{stuInfo.update_time}}</span>
                    </div>
                    <div class="middle-top-box">
                        <div class="data-box">
                            <div class="data-item">
                                <span class="data-num">{{stuInfo.subscribe}}</span>
                                <span class="text">关注</span>
                            </div>
                            <div class="data-item">
                                <span class="data-num">{{stuInfo.fans}}</span>
                                <span class="text">粉丝数</span>
                            </div>
                        </div>
                    </div>
                    <div class="middle-bottom-box">
                        <div class="data-item">
                            <span class="data-num">{{stuInfo.month_news_count}}</span>
                            <span class="text">月文章</span>
                        </div>
                        <div class="data-item">
                            <span class="data-num">{{stuInfo.view_count}}</span>
                            <span class="text">月阅读</span>
                        </div>
                        <div class="data-item">
                            <span class="data-num">{{stuInfo.likes_count}}</span>
                            <span class="text">月点赞</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-box">
            <div class="table-title">
                文章列表
            </div>
            <el-table
                    :data="articleList"
                    class="college-table"
                    size="medium"
                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#D3DEFD',}"
                    :cell-style="{fontSize: '12px',color: '#333',height:'80px'}"
            >
                <el-table-column
                        prop="user_id"
                        label="作品信息"
                        align="left"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="showing-name">{{scope.row.title}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="account_link" label="阅读数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.view_count>=0?scope.row.view_count:'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="all_fans_num" label="点赞数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.likes_count>=0?scope.row.likes_count:'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="all_fans_num" label="分享数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.share_count>=0?scope.row.share_count:'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="all_fans_num" label="推荐数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.recommend_count>=0?scope.row.recommend_count:'暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="all_fans_num" label="收藏数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.collect_count>=0?scope.row.collect_count:'暂无'}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <template v-if="articleList.length > 0">
                <div style="text-align: center; cursor: pointer; padding:10px 0;" v-if="has_more" @click="viewMore">
                    查看更多
                </div>
                <div style="text-align: center; padding:10px 0;" v-else>暂时没有更多了</div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "picDataDetails",
        data() {
            return {
                stuInfo: {},
                rank: this.$route.query.rank,
                articleList: [],
                page_no: 1,
                //是否显示'查看更多'
                has_more: false,
                beforeUrl: '',
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.beforeUrl = from.path
            })
        },
        mounted() {
            this.getUserData()
        },
        methods: {
            goBack() {
                if (this.beforeUrl == '/company/taskcenter/publish') {
                    this.$router.push({
                        path: `${this.beforeUrl}`,
                        query: {
                            isBack: true
                        }
                    })
                } else {
                    this.$router.push(this.beforeUrl)
                }
            },
            getUserData() {
                let user_account_id = this.$route.query.id
                this.$httpcompany.axiosGetBy(this.$api.intelligent_getUserBjhData, {user_account_id: user_account_id}, res => {
                    if (res.code == 200) {
                        this.stuInfo = res.data;
                        setTimeout(() => {
                            this.getGraphicList();
                        }, 100)
                    }
                })
            },
            //获取图文创作列表
            getGraphicList() {
                //暂时不传了
                // let myDate = new Date();
                // let endTime = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
                // myDate.setMonth(myDate.getMonth() - 1);
                // let startTime = myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
                let params = {
                    app_id: this.stuInfo.app_id,
                    app_token: this.stuInfo.app_token,
                    page_no: this.page_no,
                    page_size: 10,
                    articleDetails: 'true'
                }
                this.$httpcompany.axiosGetBy(this.$api.api_articleListAll, params, (res) => {
                    if (res.code === 200) {
                        this.articleList = [...this.articleList, ...res.data.items];
                        this.has_more = res.data.page.has_next
                        this.page_no++
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            gotoUrl(url) {
                window.open(url, '_blank')
            },
            //查看更多
            viewMore() {
                this.getGraphicList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .detail-contain {
        height: 100%;

        .back-box {
            display: flex;
            justify-content: space-between;

            .interactive-breadcrumb {
                font-size: 14px;
                margin-top: 9px;
            }

            .back-btn {

                ::v-deep .el-button {
                    width: 80px;
                    height: 32px;
                    background: #4D5AFF;
                    border-radius: 2px;
                    color: #ffffff;
                    line-height: 9px;
                }
            }
        }

        .head-box {
            position: relative;
            width: 100%;
            margin-top: 10px;

            .first-box {
                background-image: url("../../../assets/img/company/dataBack.png");
                height: 192px;
            }

            .second-box {
                height: 192px;
                background: #FFFFFF;
                border-radius: 8px;
                width: calc(100% - 52px);
                position: absolute;
                top: 50px;
                left: 26px;
                display: flex;

                .left-box {
                    width: 50%;
                    min-width: 404px;
                    position: relative;

                    .left-top-box {
                        margin-top: 13px;
                        width: 135px;
                        height: 30px;
                        background: #FF6C00;
                        position: relative;
                        color: #ffffff;
                        font-size: 14px;
                        line-height: 30px;
                        display: flex;

                        div {
                            margin-left: 6px;
                        }

                        span {
                            font-size: 16px;
                            font-weight: Bold;
                            margin-left: 6px;
                        }

                        &:before {
                            width: 0;
                            height: 0;
                            content: '';
                            position: absolute;
                            left: -10px;
                            border-top-left-radius: 2px;
                            border-bottom-right-radius: 2px;
                            border-top: 30px solid #FF6C00;
                            border-left: 10px solid transparent;
                        }

                        &:after {
                            width: 0;
                            height: 0;
                            content: '';
                            position: absolute;
                            right: -15px;
                            border-top-right-radius: 2px;
                            border-top: 30px solid #FF6C00;
                            border-right: 15px solid transparent;
                        }
                    }

                    .left-bottom-box {
                        display: flex;
                        padding: 17px 49px 29px 40px;

                        .left-bottom-avatar {
                            width: 90px;
                            height: 90px;

                            img {
                                width: 90px;
                                height: 90px;
                                border-radius: 50%;
                            }
                        }

                        .left-bottom-headInfo {
                            margin-top: 10px;
                            margin-left: 16px;

                            .headInfo-name {
                                color: #333333;
                                font-size: 16px;
                            }

                            .headInfo-sign {
                                margin-top: 14px;
                                font-size: 14px;
                                color: #999999;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: normal;
                                word-break: break-all;
                                word-wrap: break-word;
                                max-height: 30px;
                                line-height: 16px;
                            }

                            .headInfo-url {
                                width: 97px;
                                height: 24px;
                                background: #FFFFFF;
                                border: 1px solid #EEEEEE;
                                border-radius: 2px;
                                margin-top: 8px;
                                display: flex;
                                font-size: 12px;
                                align-items: center;
                                cursor: pointer;

                                span {
                                    margin-left: 5px;
                                }

                                .el-icon-position {
                                    margin-left: 3px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }

                .middle-box {
                    width: 690px;
                    position: relative;

                    .update-time {
                        position: absolute;
                        right: 20px;
                        top: 20px;

                        span {
                            color: #666666;
                            font-size: 12px;
                        }
                    }

                    .middle-top-box {
                        margin-top: 40px;
                        display: flex;

                        .data-box {
                            width: 293px;
                            display: flex;
                            justify-content: space-around;

                            .data-item {
                                display: flex;
                                flex-direction: column;

                                .text {
                                    color: #666666;
                                    font-size: 14px;
                                    margin-top: 8px;
                                }

                                .data-num {
                                    font-size: 24px;
                                    color: #42D7AB;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .middle-bottom-box {
                        display: flex;
                        justify-content: space-around;
                        margin-top: 20px;
                        width: 433px;

                        .data-item {
                            display: flex;
                            flex-direction: column;

                            .text {
                                color: #666666;
                                font-size: 14px;
                                margin-top: 8px;
                            }

                            .data-num {
                                font-size: 24px;
                                color: #FF6C00;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        .table-box {
            margin-top: 70px;
            background: #ffffff;

            .table-title {
                font-size: 16px;
                color: #333333;
                padding: 30px 0 20px 30px;
                position: relative;

                &:before {
                    content: '';
                    width: 3px;
                    height: 10px;
                    background: #00B47F;
                    position: absolute;
                    left: 20px;
                    top: 35px;
                }
            }

            .college-table {
                width: 100%;
                overflow: hidden;
                min-height: 520px;

                .showing-name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .pages-center {
                ::v-deep .el-pager li.active {
                    background: #4D5AFF;
                    border-radius: 50%;
                    color: #FFFFFF;
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }
    }
</style>